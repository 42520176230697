import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const PageContext = React.createContext({});

const PageProvider = ({ children }) => {
    const [page, setPage] = useState(null);

    const value = useMemo(
        () => ({
            page,
            setPage,
        }),
        [page],
    );

    return (
        <PageContext.Provider value={value}>{children}</PageContext.Provider>
    );
};

const usePageContext = () => useContext(PageContext);

PageProvider.propTypes = {
    children: PropTypes.node,
};

PageProvider.defaultProps = {
    children: undefined,
};

export { usePageContext, PageProvider };
