exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-coupon-[id]-jsx": () => import("./../../../src/pages/coupon/[id].jsx" /* webpackChunkName: "component---src-pages-coupon-[id]-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mentions-legales-jsx": () => import("./../../../src/pages/mentions-legales.jsx" /* webpackChunkName: "component---src-pages-mentions-legales-jsx" */),
  "component---src-pages-onboarding-[id]-jsx": () => import("./../../../src/pages/onboarding/[id].jsx" /* webpackChunkName: "component---src-pages-onboarding-[id]-jsx" */),
  "component---src-pages-referral-[id]-jsx": () => import("./../../../src/pages/referral/[id].jsx" /* webpackChunkName: "component---src-pages-referral-[id]-jsx" */),
  "component---src-pages-signin-jsx": () => import("./../../../src/pages/signin.jsx" /* webpackChunkName: "component---src-pages-signin-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-tarifs-jsx": () => import("./../../../src/pages/tarifs.jsx" /* webpackChunkName: "component---src-pages-tarifs-jsx" */),
  "component---src-pages-technologies-jsx": () => import("./../../../src/pages/technologies.jsx" /* webpackChunkName: "component---src-pages-technologies-jsx" */),
  "component---src-pages-vip-jsx": () => import("./../../../src/pages/vip.jsx" /* webpackChunkName: "component---src-pages-vip-jsx" */)
}

