import React from 'react';

import { PageProvider } from '@organisms/contexts/pageContext';
import { DidomiProvider } from '@organisms/contexts/didomiContext';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
    <DidomiProvider>
        <PageProvider>{element}</PageProvider>
    </DidomiProvider>
);
